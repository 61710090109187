import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, withRouter } from "react-router-dom";
import axios from 'axios';
import { motion, useAnimation } from 'framer-motion'

import { useInView } from 'react-intersection-observer';
import AboutUs from './animation/AboutSection';

import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBBadge,
} from 'mdb-react-ui-kit';

const Home = () => {
  const [data, setData] = useState([]);
  const { ref, inView } = useInView({
    threshold: 0.2
  });
  const animation = useAnimation();

  // useEffect(() => {
  //   const getAllPrizes = async () => {
  //     const response = await axios.get(`/allPrize`, {
  //       headers: {
  //         authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  //       }
  //     }).catch(err => {
  //       console.log(err);
  //     });

  //     setData(response.data.filter(prize => prize.year === 2023 & prize.quarter === 2));
  //   };

  //   getAllPrizes();
  // }, []);

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        transition: {
          type: 'spring', duration: 1.5, bounce: 0.3
        }
      });
    }
    if (!inView) {
      animation.start({ x: '-100vw' });
    }
    console.log("use effect hook, inview", inView);
  }, [inView]);

  const useStyles = makeStyles((theme) => ({
    root: {
      minHeight: '100vh',
      backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/bg1.png'})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
  }));

  return (
    <>
      <AboutUs />

    </>
  );
}

export default withRouter(Home);
