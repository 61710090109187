import React, {Component} from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import {Redirect, withRouter} from "react-router-dom";
import EventTableAdmin from "./EventTableAdmin"
import Consumer from '../ContextAPI/Context'
import {isOrganizator, isParticipant} from "../../Authentication";

class EventsAdmin extends Component {

    render() {
        return(<Consumer>
                {
                    value => {
                        const {events} = value;
                        return (
                            <div className={"mt-5"}>
                      
                                {<EventTableAdmin events = {events} />}

 
                                    <div className={"mt-5 mb-5"}>
                                        <Alert severity="info" className={"container"}>
                                            {/* <AlertTitle>Information</AlertTitle> */}
                                            Users can click + sign to add new service hours.
                                        </Alert>
                                    </div>
                                
                            </div>
                        );
                    }
                }
            </Consumer>
        )

    }
}

export default withRouter(EventsAdmin);

