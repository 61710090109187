import React from 'react';

const PDFViewer = () => {
  return (
        <div style={{ height: '100vh' }}>
          <embed src='https://atlantaccs.org/wp-content/uploads/2023/10/CCS-Volunteer-Service-Hours-explained.pdf' type="application/pdf" width="100%" height="100%" />
        </div>
      ); 
};

export default PDFViewer;