import React, {Component} from 'react';
import axios from 'axios';
import {Form} from "react-bootstrap";
class ParticipantSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            namesOfParticipants : [ ],
            username : '',
        }

    }


    componentDidMount = () => {
        this.getActiveParticipants();
    }

    getActiveParticipants = async () =>{
        await axios.get(`/activeParticipant`, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('jwtToken')
            },
        }).then(response => {
            var pt = response.data;
            let namesOfParticipants = pt.map(p =>{
                return {value :p.username , display : p.name + " "  + p.surname}
            });
            this.setState({
                namesOfParticipants : [{value: 'All', display : 'All participant'}]
                    .concat(namesOfParticipants)
            });
        })
    }

    handleNameChange = (e) => {
        this.setState({
            username : e.target.value
        },() =>{
            this.props.onSelectEvent(this.state.username);
        })
    }

    render() {
        const {username,namesOfParticipants} = this.state;
        return (
            <div>
                <Form.Control as="select" required
                              placeholder = "Select a participant"
                              value={username}
                              onChange={(e) => this.handleNameChange(e) }>
                    {namesOfParticipants.map((name) =>
                        <option key={name.value} value={name.value}>{name.display}</option>)}
                </Form.Control>
            </div>
        );
    }
}

export default ParticipantSelection;