import React from 'react';
import { MDBFooter, MDBContainer } from 'mdb-react-ui-kit';

export default function Footer() {
  var style = {
    backgroundColor: "#f2f2f2",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "60px",
    width: "100%",
}

var phantom = {
  display: 'block',
  padding: '20px',
  height: '60px',
  width: '100%',
}
  return (

    <div className='mt-5 bg-black text-black' >

       <footer className='bg-black'>
       <div style={phantom} />
        <MDBFooter style={style}  className='text-center mt-10 text-lg-left'>
            <div className='text-center p-3 mb-3' >
                &copy; {new Date().getFullYear()} Copyright:{' '}
                
                Chinese Culture School of Atlanta     &nbsp;&nbsp;&nbsp;&nbsp;      Designed by{" "}
            <a
              href="#"
              target="_blank"
            >
              Kick-Start Coding
            </a>
                
            </div>

            </MDBFooter>
        </footer>

    </div>
  );
}

