/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function DefaultFooter() {

  var style = {
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "60px",
    width: "100%",
}

var phantom = {
  display: 'block',
  padding: '20px',
  height: '60px',
  width: '100%',
}
// style={style} 
  return (
    <>
      <footer className="footer footer-default">
        <Container>
          <nav>
            <ul>
              <li>
                <a
                  href="https://www.atlantaccs.org"
                  target="_blank"
                >
                  Chinese Culture School of Atlanta
                </a>
              </li>
              <li>
                <a
                  href="https://atlantaccs.org/chinese-cultural-school-of-atlanta-%e4%ba%9e%e7%89%b9%e8%98%ad%e5%a4%a7%e4%b8%ad%e8%8f%af%e6%96%87%e5%8c%96%e5%ad%b8%e6%a0%a1/"
                  target="_blank"
                >
                  About Us
                </a>
              </li>
              <li>
                {/* <a
                  href="http://blog.creative-tim.com?ref=nukr-default-footer"
                  target="_blank"
                >
                  Blog
                </a> */}
              </li>
            </ul>
          </nav>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Designed by{" "}
            <a
              href="https://kickstartcoding.org/"
              target="_blank"
            >
              Kick-Start Coding
            </a>
            . Coded by{" "}
            <a
              href="https://kickstartcoding.org/"
              target="_blank"
            >
               (S&K)
            </a>
            .
          </div>
        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;
