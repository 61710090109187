import React from 'react';
import { About, Description, Image } from './styles';
import styled from 'styled-components';
//Images
import homeImg1 from './summerfinal.png';
import school from './school.png';
import { titleAnim, fade, photoAnim } from './animation';
import { motion } from 'framer-motion';
import Wave from './Wave';

const AboutSection = () => {
  return (
    <About>
      <Description>
      <div>
        {/* <div>
        <Image>
        <motion.img variants={photoAnim} src={school} alt="School"  className="box"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 2,
        delay: 3.5,
        ease: [0, 0.71, 0.2, 1.01]
      }}/>
      </Image>

        </div> */}


          <motion.div  className="box"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 2,
        delay: 1,
        ease: [0, 0.71, 0.2, 1.01]
      }}>
            <motion.h1 className='bigger text-white' variants={titleAnim}>Record volunteer hours</motion.h1>
          </motion.div>
          <motion.div  className="box bolder"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 2,
        delay: 1.5,
        ease: [0, 0.71, 0.2, 1.01]
      }}>
            <motion.h2 className=' text-white' variants={titleAnim}>Track service times</motion.h2>
          </motion.div>
          <motion.div  className="box bolder"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 2,
        delay: 2,
        ease: [0, 0.71, 0.2, 1.01]
      }}>
            <motion.h2  className=' text-white' variants={titleAnim}>View your comprehensive report</motion.h2>
          </motion.div>
    </div>


        <motion.p  variants={fade}
        className="box text-white"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 2,
          delay: 3,
          ease: [0, 0.71, 0.2, 1.01]
        }}
        >
          Chinese Cultural School of Atlanta invites you to register with the [VHS] Volunteer Hour System
        </motion.p>
        
        
        
        {/* <motion.button variants={fade}>Contact Us</motion.button> */}
      </Description>
     

      <Image>
        <motion.img variants={photoAnim} src={homeImg1} className="box"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 2,
        delay: 3.5,
        ease: [0, 0.71, 0.2, 1.01]
      }}/>
      </Image>
   
      <Wave />
      
    </About>
  );
};

const Hide = styled.div`
  overflow: hidden;
`;

export default AboutSection;
