import React, {Component} from 'react';
// import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect,browserHistory} from 'react-router-dom';
import Events from "./components/Event/Events";
import EventsStudent from "./components/Event/EventsStudent";
import EventsAdmin from "./components/Event/EventsAdmin";
import Login from "./components/Login";
import Home from "./components/Home";
import Footer from "./components/Footer";
import DefaultFooter from "./components/DefaultFooter";
import NavigationBar from "./components/Navigation Bar";
import IndexNavbar from "./components/IndexNavbar";
import IndexHeader from './components/IndexHeader';
import NotFound from "./components/static/NotFound";
import RegisterForm from "./components/Forms/RegisterForm";
import PrivateRoute from "./components/Routers/PrivateRoute";
import OrganizatorRoute from "./components/Routers/OrganizatorRoute";
import ParticipantRoute from './components/Routers/ParticipantRoute';
import AddEventForm from "./components/Event/Forms/AddEventForm";
import ParticipantEvents from "./components/Participant/ParticipantEvents";
import ParticipantsOfEvent from "./components/Participant/ParticipantsOfEvent";
import PDFViewer from "./components/PDFViewer";
import PDFViewerForm from "./components/PDFViewerForm";



class App extends Component {

    render() {
        return (
            // <div className={useStyles().root}>
            // <CssBaseline />
            <Router>
                {/* <IndexNavbar/> */}
                <div className="wrapper">
                   {/* <IndexHeader />  */}
                   <NavigationBar/>
                    <div className="main">
                        <Switch>
                            {/* <Route exact path  ={"/"}><Redirect to = {"/login"}></Redirect></Route> */}
                            <Route exact path  ={"/"} component ={Home}/>
                            <Route exact path = {"/login"} component ={Login}/>
                            <Route exact path = {"/register"} component= {RegisterForm}/>
                            <PrivateRoute exact path={"/eventsAdmin"} component={EventsAdmin}/>
                            <PrivateRoute exact path={"/eventsStudent"} component={EventsStudent}/>
                            <PrivateRoute exact path={"/events"} component={Events}/>  
                            <PrivateRoute exact path={"/eligibility"} component={PDFViewer}/>   
                            <PrivateRoute exact path={"/form"} component={PDFViewerForm}/>                  
                            <PrivateRoute exact path={"/event/:eventName"} component={Event}/>
                            <PrivateRoute exact path={"/events/:username"} component={AddEventForm}/>
                            <OrganizatorRoute exacth path ={"/participants/:eventName"} component ={ParticipantsOfEvent}/>
                            <ParticipantRoute exact path ={"/myEvents/:username"} component={ParticipantEvents}/>
                            <Route exact path="/userguide" render={() => {window.location.href="SEAS.html"}} />
                        
                        <Route component={NotFound}/>
                        </Switch>
                    </div>
                    {/* <DefaultFooter/> */}
                    <Footer/>
                </div>
            </Router>
            // </div>
        );
    }
}

export default App;
