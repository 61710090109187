import React, {Component} from 'react';
import {Card, Form, Button, Col, InputGroup} from 'react-bootstrap';
import axios from 'axios';
import PositionedSnackbar from "../../static/Snackbars/PositionedSnackbar";
import Consumer from "../../ContextAPI/Context"
import CloseButton from 'react-bootstrap/CloseButton';


class AddEventForm extends Component {

    constructor(props) {
        super(props);
        this.state = this.initialState;
    }

    initialState = {
        name : '',
        serviceDate : '',
        org : '',
        phone: '',
        email: '',
        address : '',
        signingAuth : '',
        isSubmittedForm: false,
        message : '',
        messageType : '',
        isAdded : false,
        city : '',
        state : 'No',
        zip : 'No',
        serviceDuty: '',
        hours: 0, 
        imageUrl: '',
        file : null,
        errorMsg: '', 
        isSuccess: false, 
    }

    resetAllInputs = () =>{
        this.setState(() => this.initialState);
    }

    addEvent = async (e,dispatch) =>{
        const {name,serviceDate,org, phone,
            email,signingAuth,
            address, city, state, zip, serviceDuty, hours, imageUrl,description, file} = this.state;

         e.preventDefault();
        var event  = {
            name : name.trim(),
            serviceDate,
            org,
            phone,
            email,
            signingAuth,
            address,
            city,
            state,
            zip,
            serviceDuty, 
            hours,
            imageUrl,
            description,
            file
        }
        var organizatorUsername = localStorage.getItem("username");
        const response = await axios.post(`/events/add/${organizatorUsername}`,
            event, {
            headers : {
                'Authorization' : "Bearer " + localStorage.getItem("jwtToken")
            }
        }).catch(err => {
           this.props.history.push("/notFound404");
           console.log(err);
        })

        this.showMessage(response.data);
        if(this.isAdditionSuccess(response.data.messageType)) {
            this.saveFile();
            dispatch({ type: 'addEvent', payload: event })

        }
        setTimeout(() => {
            this.setState({
                isSubmittedForm : false,
            })
        }, 2000);

        this.props.history.push('/eventsStudent');

    }

    saveFile = async () => {

        let formData = new FormData();

        formData.append("file", this.state.file);
        const response = await axios.post(`/file/upload`, 
        formData,
        {
            headers : {
                "Content-Type": "multipart/form-data",
            }
        }).catch(err => {
            // this.props.history.push('/notFound404');
            console.log(err);
        });
        this.props.history.push('/eventsStudent');

    }

    showMessage = (messageResponse) =>{
        this.setState({
            isSubmittedForm : true,
            message : messageResponse.message,
            messageType :messageResponse.messageType
        })
    }

    isAdditionSuccess = (messageType) => {
        return messageType === 'SUCCESS'
    }

    handleCheckboxChange = (e) => {
        this.setState({
          state: e.target.checked ? "Yes" : "No",
        });
      }

    handleCheckboxChange1 = (e) => {
    this.setState({
        zip: e.target.checked ? "Yes" : "No",
    });
    }

    handleEventTypeChange = (e) => {
        this.setState({
            eventType : e.target.value
        })
    }

    changeInput = (e) =>{
        this.setState({
            [e.target.name]:e.target.value
        });
    }
    changeLecturerUsernameWith = (val) =>{
        this.setState({
            lecturerUsername : val
        })
    }

    handleFile = (e) => {
        const MIN_FILE_SIZE = 1024 // 1MB
        const MAX_FILE_SIZE = 4257 // 4MB
        let file = e.target.files[0]

        if (!file) {
            this.state.errorMsg = '';
            this.state.isSuccess = false;
            this.setState({
                errorMsg: '',
                isSuccess: false,
            })
            return
          }
      
          const fileSizeKiloBytes = file.size / 1024
      
          if(fileSizeKiloBytes > MAX_FILE_SIZE){
            this.setState({
                errorMsg: 'File size is greater than maximum limit. Please reduce the iamge size and try again.',
                isSuccess: false,
            })
            return
          }
    

        this.setState({
            file : file,
            imageUrl : file.name,
            errorMsg: '',
            isSuccess: true,
        })
    }

    close = () =>{
        this.props.history.push('/eventsStudent');
    }

    render() {
        var style = {
            display: "block",
      
        };
        const {name,serviceDate,org, phone,
            email,signingAuth,address,
               isSubmittedForm,
                message,messageType, city, state, zip, serviceDuty, hours, imageUrl, description,isActivitySponsoredByCCS,isActivitySponsoredByCCS1 , file, isSuccess, errorMsg} = this.state;

        return (
            <Consumer>
                {
                    value =>{
                        const {dispatch} = value;
                        return(
                        
                               <div >
                                <Card className={"container w-50 mt-5"} style={style}>
                                    <Card.Header className="bg-secondary text-white">
                                       Volunteer Hours Registration
                                       
                                       <CloseButton className="text-white"  onClick={this.close} />
                                     
                                       <h5>Welcome to the Volunteer Hours Recording System (VHS) for Chinese Cultural School of Atlanta.
All volunteer hours must be recorded in a timely and legitimate manner. Supporting documents are to be uploaded via the system. </h5>
<ul>
             <li>Must be Chinese Cultural School of Atlanta currently registered student.</li>
             <li>YOU WILL NOT RECEIVE CREDITS FOR YOUR HOURS if the sponsors you listed cannot be reached by emails or phone calls. No hours will be verified if the supporting picture is not submitted accordingly. </li>
             <li>Recurring events must be submitted individually by date with no exception.</li>
             <li>CCS requires members to volunteer from at least 3 distinct organizations/events, volunteering in a variety of activities is promoted. </li>
             <li> It is the responsibility of the volunteer to record and upload each volunteer event / activity. CCS reserves the right to verify all information provided. </li>

             <li>Volunteers are to provide the original documents for further verification when requested.</li>

             <li>Volunteers must complete qualifying volunteer hours to be eligible for the Presidential Volunteer Service Award. Hours required to earn awards differ in each age group. Please refer to the Eligibility Requirements for details.  </li>

             <li> Please do not share your personal credentials with others. </li>
</ul>
                                    </Card.Header>
                                    <Form onReset={this.resetAllInputs} onSubmit={(e) => this.addEvent(e,dispatch)} >
                                        <Card.Body>

                                        <Form.Row>
                                        <Form.Group as={Col} controlId="formGridsigningAuth">
                                                    <Form.Label>Before proceeding, I attest that this submission I am about to do is honest and I will take full responsibility for the validity of my hours. </Form.Label>
                                                    <InputGroup>
                                                        <Form.Control required autoComplete="off"
                                                                      type="text" name="signingAuth"
                                                                      value={signingAuth} onChange={(e) => this.changeInput(e)}
                                                                      placeholder="Type Your Name" />
                                                    </InputGroup>
                                                </Form.Group>                             
                                               
                                        </Form.Row>
                                        <Form.Row>
                                             <Form.Group as={Col} controlId="formGridIsCCS">
                                                <Card.Header >
                                                        <Form.Check
                                                        type="checkbox"
                                                        label="Is the activity sponsored by CCS?"
                                                        checked={isActivitySponsoredByCCS}
                                                        onChange={this.handleCheckboxChange}
                                                        />
                                                    
                                                    </Card.Header>
                                                </Form.Group>                             
                                               
                                        </Form.Row>
                                            
                                        <Form.Row>
                                                <Form.Group as={Col} controlId="formGridOrg">
                                                    <Form.Label>Your Class in Chinese Cultural School of Atlanta</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control required autoComplete="off"
                                                                      type="text" name="org"
                                                                      value={org} onChange={(e) => this.changeInput(e)}
                                                                    //   className={"text-white"}
                                                                      placeholder="Enter A9, A10, A11 or A12" />
                                                    </InputGroup>
                                                </Form.Group>                              
                                               
                                        </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="formGridName">
                                                    <Form.Label>Event</Form.Label>
                                                    <Form.Label> (Location + Event Name; i.e., Chinese Cultural School of Atlanta Christmas Party; Duluth High School Cafeteria)</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control required autoComplete="off"
                                                                      type="text" name="name"
                                                                      value={name} onChange={(e) => this.changeInput(e)}
                                                                    //   className={"text-white"}
                                                                      placeholder="Event" />
                                                    </InputGroup>
                                                </Form.Group>
                                            </Form.Row>

                                            <Form.Row>
                                                <Form.Group as={Col} controlId="formGridDescription">
                                                    <Form.Label>Task Description (Max 255 words)</Form.Label>
                                                    <Form.Label>In 2-3 sentences, please describe in detail what tasks you have completed for your event.</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control required autoComplete="off" 
                                                                      type="text" name="serviceDuty"
                                                                      value={serviceDuty} onChange={(e) => this.changeInput(e)}
                                                                    //   className={"text-white"}
                                                                      placeholder="Task Description" />
                                                    </InputGroup>
                                                </Form.Group>
                                               
                                            </Form.Row>


                                            <Form.Row>
                                   
                                                 <Form.Group as={Col} controlId="formGridserviceDate">
                                                    <Form.Label>Date of Volunteering</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control required autoComplete="off"
                                                                      type="date" name="serviceDate"
                                                                      value={serviceDate} onChange={(e) => this.changeInput(e)}
                                                                    //   className={" text-white"}
                                                                      placeholder="Starting date" />
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formGridHours">
                                                    <Form.Label>Number of Hour Volunteered</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control required autoComplete="off"
                                                                      type="number" name="hours"
                                                                      min = "0" step="0.25"
                                                                      value={hours} onChange={(e) => this.changeInput(e)}
                                                                    //   className={" text-white"}
                                                                      placeholder="Hours" />
                                                    </InputGroup>
                                                </Form.Group>
                                            
                                            </Form.Row>

                                            <Form.Row>
                                                 <Form.Group as={Col} controlId="formGridAddress">
                                                    <Form.Label>Sponsor First Name</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control required autoComplete="off"
                                                                      type="text" name="address"
                                                                      value={address} onChange={(e) => this.changeInput(e)}
                                                                    //   className={"bg-dark text-white"}
                                                                      placeholder="Sponsor First Name" />
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formGridCity">
                                                    <Form.Label>Sponsor Last Name</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control required autoComplete="off"
                                                                      type="text" name="city"
                                                                      value={city} onChange={(e) => this.changeInput(e)}
                                                                    //   className={"bg-dark text-white"}
                                                                      placeholder="Sponsor Last Name" />
                                                    </InputGroup>
                                                </Form.Group>
                                            </Form.Row>


                                            <Form.Row>
                                                 <Form.Group as={Col} controlId="formGridphone">
                                                    <Form.Label>Sponsor Phone Number</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control required autoComplete="off"
                                                                      type="text" name="phone"
                                                                      value={phone} onChange={(e) => this.changeInput(e)}
                                                                    //   className={" text-white"}
                                                                      placeholder="Sponsor Phone Number" />
                                                    </InputGroup>
                                                </Form.Group>
                                                
                                                <Form.Group as={Col} controlId="formGridemail">
                                                    <Form.Label>Sponsor Email</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control required autoComplete="off"
                                                                      type="text" name="email"
                                                                     
                                                                      value={email} onChange={(e) => this.changeInput(e)}
                                                                    //   className={" text-white"}
                                                                      placeholder="Sponsor Email" />
                                                    </InputGroup>
                                                </Form.Group>

                                            </Form.Row>
                                         
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="formGridImage">
                                                    
                                                    <Form.Label>Please attach a photo.
Each picture upload must follow this naming mechanism: First name and last initial-event name-mmddyy (EXAMPLE: AndrewL-CCSSpringRegistration-113022). The image size cannot exceed 3MB. Accepted image types are PNG, JPG and JPEG.

 </Form.Label>
                                                    <InputGroup>
                                                        <Form.Control 
                                                                      type="file" 
                                                                       onChange={(e) => this.handleFile(e)}
                                                                       accept=".png,.jpg,.jpeg"
                                                                      />
                                                                        {isSuccess ? <p className="text-success">File size validation successful</p> : null}
                                                                   <p className="text-danger">{errorMsg}</p>
                                                    </InputGroup>
                                                </Form.Group>
                                               
                                            </Form.Row>
                                            <Form.Row>
                                             <Form.Group as={Col} controlId="formGridIsUnder">
                                                <Card.Header >
                                                        <Form.Check
                                                        type="checkbox"
                                                        label="I understand that no hours will be verified if supporting pictures are not correctly submitted.
                                                        "
                                                        checked={isActivitySponsoredByCCS1}
                                                        onChange={this.handleCheckboxChange1}
                                                        />
                                                                
                                                    
                                                    </Card.Header>
                                                </Form.Group>                             
                                               
                                        </Form.Row>
                                        </Card.Body>
                                        <Card.Footer style={{"textAlign":"right"}}
                                                     className={"d-flex justify-content-between text-black"}>
                                            <Button  variant="info" type="reset">
                                            Reset
                                            </Button>
                                            <Button variant="success" type="submit" disabled = {!isSuccess}>
                                            Submit
                                            </Button>
                                            
                                          
                                        </Card.Footer>
                                    </Form>
                                </Card>
                                {isSubmittedForm  ?
                                    <PositionedSnackbar
                                        message = {message}
                                        messageType = {messageType}
                                        vertical = {"bottom"}
                                        horizontal = {"right"}/>
                                        : null}
                               
                                {messageType === "SUCCESS" ? this.props.history.push('/eventsStudent') : null}



                            </div>
                        );
                    }
                }
            </Consumer>

        );
    }
}

export default AddEventForm;