import React, {Component} from 'react';
import MaterialTable from 'material-table';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import PositionedSnackbar from "../static/Snackbars/PositionedSnackbar";
import UpdateEventForm from "./Forms/UpdateEventForm";
import Consumer from '../ContextAPI/Context';
import {isOrganizator, isParticipant} from "../../Authentication";
import CountUp from 'react-countup';
import {Card, Form, Button, Col, InputGroup} from 'react-bootstrap';
import {getEvents, getUserEvents} from "../../HelperFunctions/EventHelpers";
import ParticipantSelection from './ParticipantSelection';
import {
    MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardFooter,
    MDBRow,
    MDBCol
  } from 'mdb-react-ui-kit';

class EventTableAdmin extends Component {
    state = {
       
        isAdditionRequest : false,
        isDeleteRequest : false,
        isUpdateRequest : false,
        responseMessageOfDeleteRequest : '',
        responseMessageTypeOfDeleteRequest  : '',
        updatedEventName: '',
        events1: [],
        username: localStorage.getItem('username'),
        point: 0,
        currentEvent : 0,
        currentTotal : 0,
        completeEvent : 0,
        completeTotal : 0,
        currentEvents: [],
        completeEvents: [],
        eventPoints : [],
        year: 0,
        selectedUserName : 'All',
          
    }

    changeUserNameWith = (val) =>{
      this.setState({
         selectedUserName : val
      }, this.loadSearchedEvents)
  }


  handleYearChange  = (e) => {
    e.preventDefault();
    this.setState({
        year : e.target.value,
    }, this.loadSearchedEvents)
}

    componentDidMount = async () => {
      const participantUsername = this.state.selectedUserName;
      const response = await getUserEvents(participantUsername, this.state.year);

      this.setState({
          events1 : response.data,
          currentEvents : participantUsername === 'All' ? response.data.filter( p => p.state === 'Yes' ) : response.data.filter( p => (p.username === this.state.selectedUserName) && p.state === 'Yes' ),
          completeEvents : participantUsername === 'All' ? response.data.filter( p => p.state !== 'Yes' ) : response.data.filter( p => (p.username === this.state.selectedUserName) && p.state !== 'Yes' ) ,
          eventPoints : participantUsername === 'All' ? response.data : response.data.filter( p => (p.username === this.state.selectedUserName) ) , 
      })
    };
 

    getEventsOfParticipant = async () => {
        const participantUsername = localStorage.getItem('username');
        console.log(participantUsername);
        const response = await axios.get(`/eventsOfParticipant/${participantUsername}`, {
            headers : {
                authorization : 'Bearer ' + localStorage.getItem('jwtToken')
            }
        }).catch(err => {
            this.props.history.push('/notFound404');
        });

        this.setState({
            events : response.data,
            currentEvents : response.data.filter(event => new Date(event.endDate) > new Date()),
            completeEvents : response.data.filter(event => new Date(event.endDate) <= new Date()),
            eventPoints : response.data.map(event => event.point),
            eventNames : response.data.map(event => event.name),
        })
    }

    deleteEvent = async  (e,eventName,dispatch) =>{

        e.preventDefault();
        axios.delete(`/events/delete/${eventName}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("jwtToken")
            },
            data: {
                name: eventName
            }
        }).then(response => {
            this.showMessageOfDeleteRequest(response.data);
            setTimeout(() => {
                this.setState({
                    isDeleteRequest : false,
                })
                if(this.isDeletionSuccess(response.data.messageType)) {
                    dispatch({ type: 'deleteEvent', payload: eventName })
                }
            }, 3000);
        }).catch(err => {
           this.props.history.push("/notFound404");
        });

    }
    showMessageOfDeleteRequest = (messageResponse) =>{
        this.setState({
            isDeleteRequest : true,
            responseMessageOfDeleteRequest : messageResponse.message,
            responseMessageTypeOfDeleteRequest : messageResponse.messageType
        })

    }
    isDeletionSuccess = (messageType) =>{
        return messageType === "SUCCESS"
    }

    openUpdateModal = (e,eventName) =>{
        this.setState({
            isUpdateRequest : true,
            updatedEventName : eventName
        })
    }

    closeUpdateModal = () =>{
        this.setState({
            isUpdateRequest: false,
        })

    }

    goToEventPage = (e,eventName) =>{
        this.props.history.push(`/event/${eventName}`)
    }

    goToEventParticipantPage = (e,eventName) =>{
        this.props.history.push(`/participants/${eventName}`)
    }

    loadSearchedEvents = async () =>{
      const participantUsername = this.state.selectedUserName;
      const response = await getUserEvents(participantUsername, this.state.year);

      this.setState({
          events1 : response.data,
          currentEvents : participantUsername === 'All' ? response.data.filter( p => p.state === 'Yes' ) : response.data.filter( p => (p.username === this.state.selectedUserName) && p.state === 'Yes' ),
          completeEvents : participantUsername === 'All' ? response.data.filter( p => p.state !== 'Yes' ) : response.data.filter( p => (p.username === this.state.selectedUserName) && p.state !== 'Yes' ) ,
          eventPoints : participantUsername === 'All' ? response.data : response.data.filter( p => (p.username === this.state.selectedUserName) ) , 
      })
 }



    render() {
        //const cardInfo = this.events

        const{isAdditionRequest,updatedEventName,isUpdateRequest,isDeleteRequest,
        responseMessageOfDeleteRequest, responseMessageTypeOfDeleteRequest, point,  currentEvents, completeEvents, events1, selectedUserName} = this.state;
        let username = localStorage.getItem("username");
        return(<Consumer>
            {
                value => {
                     const {dispatch} = value;
                    return(
                        <div>
                                     <div className='text-center  mb-3' >
                                      <h1 className='text-white'>Dashboard</h1>
                                    </div>
                                    <div className={"container w-50 "}>
                <form className='  mb-5'>
                         <Form.Group>
                         <Form.Label>Select Year</Form.Label>
                            <InputGroup>
                                <Form.Control as="select" required
                                    value={this.state.year}
                                    onChange={(e) => this.handleYearChange(e)}>
                                    <option value="0"> All </option>
                                    <option value="2024">2024 (08/2023 to 07/2024)</option> 
                                    <option value="2025">2025 (08/2024 to 07/2025)</option>                                
                                </Form.Control>
                            </InputGroup>
                                                    
                        </Form.Group>
                </form>
                {/* <form onSubmit={(e) => this.createRaffle(e)}> */}
                    <div className="col-md-12 text-center mt-5 mb-5">
                        <ParticipantSelection
                            onSelectEvent = {this.changeUserNameWith}
                        />
{/*                     
                        <button className={"btn btn-primary mt-5 "}
                            type="submit">Draw Raffle Winner!</button> */}
                    </div>
                {/* </form> */}
                </div>


    <div className={"ml-5 mr-5 mb-5"}>
      <MDBRow className='row-cols-1 row-cols-md-4 g-4 mb-5'>

      <MDBCol>
        <MDBCard className='h-100'>
        
        <MDBCardBody className='bg-danger shadow-1-strong'>
            <MDBCardTitle><h1 className= 'text-white text-center'>{this.state.events1.length}</h1></MDBCardTitle>


          </MDBCardBody>
          <MDBCardFooter className='bg-danger shadow-1-strong'>
            <h6 className= 'text-white text-center'>Events participated</h6>
          </MDBCardFooter>
        </MDBCard>
      </MDBCol>



       
      <MDBCol>
        <MDBCard className='h-100'>
          
         
          <MDBCardBody className='bg-success shadow-1-strong'>
            <MDBCardTitle><h1 className= 'text-white text-center'>{ this.state.currentEvents.reduce((a,v) => a = a + v.hours, 0)}</h1></MDBCardTitle>

          </MDBCardBody>
          <MDBCardFooter className='bg-success shadow-1-strong'>
            <h6 className= 'text-white text-center'> CCS Hours</h6>
          </MDBCardFooter>
        </MDBCard>
      </MDBCol>


      <MDBCol>
        <MDBCard className='h-100'>
          
        <MDBCardBody className='bg-warning shadow-1-strong'>
            <MDBCardTitle><h1 className= 'text-black text-center'>{this.state.completeEvents.reduce((a,v) => a = a + v.hours, 0) } </h1></MDBCardTitle>

          </MDBCardBody>
          <MDBCardFooter className='bg-warning shadow-1-strong'>
            <h6 className= 'text-black text-center'>Community Hours</h6>
          </MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          
        <MDBCardBody className='bg-info shadow-1-strong'>
            <MDBCardTitle><h1 className= 'text-white text-center'>{this.state.eventPoints.reduce((a,v) => a = a + v.hours, 0) }</h1></MDBCardTitle>


          </MDBCardBody>
          <MDBCardFooter className='bg-info shadow-1-strong'>
            <h6 className= 'text-white text-center'>Total Volunteer Hours</h6>
          </MDBCardFooter>
        </MDBCard>
      </MDBCol>
      
      
    </MDBRow>
        
    <MaterialTable
                                     title={<Typography variant="h4" component="h5">
                                     CCS Services
                                 </Typography>}
                                    columns={[
                                        { title: 'Event', field: 'name',
                                          headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          }
                                        },   
                                        { title: 'Date of Volunteering', field: 'serviceDate', type : 'date',  headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          }},                                   
                                        {title : 'CCS Class', field : 'org', headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          }},
                                        //   {title : 'Signing Auth', field : 'signingAuth', headerStyle: {
                                        //     backgroundColor: '#039be5',
                                        //     color: '#FFF'
                                        //     }},
                            
                                        { title: 'Task Description', field: 'serviceDuty', 
                                        headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          }},
                                        { title : 'Sponsor First Name', field : 'address',
                                        headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          }},
                                          { title: 'Sponsor Last Name', field: 'city',  headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          }},
                                        // { title: 'State', field: 'state', headerStyle: {
                                        //     backgroundColor: '#039be5',
                                        //     color: '#FFF'
                                        //   }},
                                        // { title: 'Zip', field: 'zip', headerStyle: {
                                        //     backgroundColor: '#039be5',
                                        //     color: '#FFF'
                                        //   }},
                                          { title: 'Sponsor Phone', field: 'phone', headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          }},
                                          { title: 'Sponsor Email', field: 'email', headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          }},
                                      
                                        {title : 'Service Hours', field : 'hours', headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                            }},
                                            { title: '', field: 'imageUrl', render: item => <img src={`${process.env.PUBLIC_URL}/uploads/${item.imageUrl}`} alt="" border="3" height="100" width="100" 
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                              const imageUrl = `${process.env.PUBLIC_URL}/uploads/${item.imageUrl}`;
                                              const newWindow = window.open(imageUrl, '_blank', 'width=800,height=600');
                                              if (newWindow) {
                                                newWindow.focus();
                                              } else {
                                                // Handle pop-up blocked by browser
                                                alert('Please allow pop-ups to view the full image.');
                                              }
                                            }}
                                            
                                            />},
                                    ]}
                                    data={ selectedUserName === 'All' ? events1.filter( p => p.state === 'Yes' ) :  events1.filter( p => (p.username === selectedUserName) && p.state === 'Yes' ) }
                                    actions={[
                            
                                        {
                                            icon: 'delete',
                                            tooltip: 'Delete',
                                            onClick: (e, rowData) => this.deleteEvent(e, rowData.name, dispatch)
                                        },
                                        // {
                                        //     icon: 'add',
                                        //     tooltip: 'Add event',
                                        //     isFreeAction: true ,
                                        //     onClick : (event) => this.setState({
                                        //         isAdditionRequest : true,
                                        //     })
                                        // } 
                                    ]}
                                    options={{
                                        actionsColumnIndex: -1,
                                        headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          },
                                          exportButton: {
                                            csv: true,
                                            pdf: false,
                                         },
                                          exportAllData: true,
                                          pageSize: 10,
                                          exportFileName: 'CCS Volunteer Hours',
                                          pagination: true 

                                    }}
                                    />
                                </div>
                                 <div className={"ml-5 mr-5 mt-5"}>
                                <MaterialTable
                                     title={<Typography variant="h4" component="h5">
                                     Community Services
                                 </Typography>}
                                    columns={[
                                        { title: 'Event', field: 'name',
                                          headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          }
                                        },   
                                        { title: 'Date of Volunteering', field: 'serviceDate', type : 'date',  headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          }},                                   
                                        {title : 'CCS Class', field : 'org', headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          }},
                                        //   {title : 'Signing Auth', field : 'signingAuth', headerStyle: {
                                        //     backgroundColor: '#039be5',
                                        //     color: '#FFF'
                                        //     }},
                            
                                        { title: 'Task Description', field: 'serviceDuty', 
                                        headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          }},
                                        { title : 'Sponsor First Name', field : 'address',
                                        headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          }},
                                          { title: 'Sponsor Last Name', field: 'city',  headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          }},
                                        // { title: 'State', field: 'state', headerStyle: {
                                        //     backgroundColor: '#039be5',
                                        //     color: '#FFF'
                                        //   }},
                                        // { title: 'Zip', field: 'zip', headerStyle: {
                                        //     backgroundColor: '#039be5',
                                        //     color: '#FFF'
                                        //   }},
                                          { title: 'Sponsor Phone', field: 'phone', headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          }},
                                          { title: 'Sponsor Email', field: 'email', headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          }},
                                      
                                        {title : 'Service Hours', field : 'hours', headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                            }},
                                            { title: '', field: 'imageUrl', render: item => <img src={`${process.env.PUBLIC_URL}/uploads/${item.imageUrl}`} alt="" border="3" height="100" width="100" 
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                              const imageUrl = `${process.env.PUBLIC_URL}/uploads/${item.imageUrl}`;
                                              const newWindow = window.open(imageUrl, '_blank', 'width=800,height=600');
                                              if (newWindow) {
                                                newWindow.focus();
                                              } else {
                                                // Handle pop-up blocked by browser
                                                alert('Please allow pop-ups to view the full image.');
                                              }
                                            }}
                                            
                                            />},
                                    ]}
                                    data={ selectedUserName === 'All' ? events1.filter( p => p.state !== 'Yes' )  :  events1.filter( p => (p.username === selectedUserName) && p.state !== 'Yes' )    }
                                    actions={[
                            
                                        {
                                            icon: 'delete',
                                            tooltip: 'Delete',
                                            onClick: (e, rowData) => this.deleteEvent(e, rowData.name, dispatch)
                                        },
                                        // {
                                        //     icon: 'add',
                                        //     tooltip: 'Add event',
                                        //     isFreeAction: true ,
                                        //     onClick : (event) => this.setState({
                                        //         isAdditionRequest : true,
                                        //     })
                                        // } 
                                    ]}
                                    options={{
                                        actionsColumnIndex: -1,
                                        headerStyle: {
                                            backgroundColor: '#039be5',
                                            color: '#FFF'
                                          },
                                          exportButton: {
                                            csv: true,
                                            pdf: false,
                                         },
                                          exportAllData: false,
                                          pageSize: 10,
                                          exportFileName: 'Community Volunteer Hours',
                                          pagination: true 

                                    }}
                                    />
                            </div>
                            {isAdditionRequest ? this.props.history.push(`/events/${username}`) : null }
                            {isDeleteRequest  ?
                                <PositionedSnackbar
                                    message = {responseMessageOfDeleteRequest}
                                    messageType = {responseMessageTypeOfDeleteRequest}
                                    vertical = {"bottom"}
                                    horizontal = {"right"}/> : null
                            }
                            {isUpdateRequest ?
                                <UpdateEventForm
                                    open = {isUpdateRequest}
                                    handleClose = {this.closeUpdateModal}
                                    eventName = {updatedEventName}/>
                                : null }
    
          
                              

       
                                    
                        </div>

                    );
                }
            }
        </Consumer>)

    }
}

export default withRouter(EventTableAdmin);

