import React from 'react';

const PDFViewerForm = () => {
  return (
    <div style={{ height: '100vh' }}>
      <embed src='https://atlantaccs.org/wp-content/uploads/2023/10/Community-Service-Log.pdf' type="application/pdf" width="100%" height="100%" />
    </div>
  );
};

export default PDFViewerForm;