import React, {Component} from 'react';
import {isOrganizator} from "../../../Authentication";
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import Consumer from '../../ContextAPI/Context'
import {withRouter} from "react-router-dom";
import PositionedSnackbar from "../../static/Snackbars/PositionedSnackbar";
class UpdateEventForm extends Component {

    state = {
        isModalOpen : this.props.open,
        name : '',
        serviceDate : '',
        org : '',
        phone : '',
        email : '',
        address : '',
        isSubmittedForm: false,
        isEventStarted : false,
        message : '',
        messageType : '',
        isDisable : false,
        city : '',
        state : '',
        zip : '',
        signingAuth : '' ,
        serviceDuty: '',
        hours: 0, 
        imageUrl: '',
        description: '',
        file: null
    }
    componentDidMount = async () => {
        if(!isOrganizator()) {
            this.props.history.push('/notFound404');
        }
        const response = await axios.get(`/events/${this.props.eventName}`, {
            headers : {
                authorization : 'Bearer ' + localStorage.getItem('jwtToken')
            }
        }).catch(err => {
            this.props.history.push('/notFound404');
        });

        if(this.isEventStarted(response.data.serviceDate)) {
            this.showErrorMessageAboutEventAs('This event has concluded and cannot be updated. !');
        }
        else {
           this.getValuesOfEvent(response.data);
        }
    }

    isEventStarted = (serviceDate) => {
        var eventserviceDate = new Date(serviceDate);
        var currentDate = new Date();
        return currentDate > eventserviceDate;

    }

    showErrorMessageAboutEventAs = (errorMessage) =>{
        this.setState({
            isEventStarted : true,
            message : errorMessage,
            messageType : 'ERROR',
            isDisable : true,
        })
    }

    getValuesOfEvent = (event) =>{
    const { name, org,serviceDate,email,phone,address, city,
        state,
        zip,
        signingAuth,serviceDuty,
        hours, 
        imageUrl,
        description } = event;
        this.setState({
            name,
            org,
            serviceDate,
            address,
            city,
            state,
            zip,
            signingAuth,
            serviceDuty,
            hours, 
            imageUrl,
            description,
            email,
            phone
        });
    }

    updateEvent = async (e,dispatch) =>{
        e.preventDefault();
        const { name, org,serviceDate,address, city, state, zip, signingAuth,serviceDuty,
            hours, 
            imageUrl,
            description,
            email,
            phone} = this.state;

        const updatedEvent = {
            name : name.trim(),
            org,
            serviceDate,
           
            address,
            
            city,
            state,
            zip,
         
            signingAuth,
            serviceDuty,
            hours, 
            imageUrl,
            description,
            email,
            phone
        }
        const response = await axios.put(`/events/${this.props.eventName}`, updatedEvent,{
            headers : {
                authorization : 'Bearer ' + localStorage.getItem('jwtToken')
            }
            }).catch(err => {
                this.props.history.push('/notFound404');
        });

        this.showUpdateOperationMessage(response.data);
        if(this.isUpdateSuccess(response.data.messageType)){
            dispatch({ type: 'updateEvent', payload:[this.props.eventName,updatedEvent]});
            this.preventToSubmitFormMultipleTimes();
        }
    }

    showUpdateOperationMessage = (messageResponse) =>{
        this.setState({
            isSubmittedForm : true,
            message : messageResponse.message,
            messageType : messageResponse.messageType
        })
    }
    isUpdateSuccess = (messageType) =>{
        return messageType === 'SUCCESS'
    }

    preventToSubmitFormMultipleTimes = () =>{
        this.setState({isDisable : true})
    }


    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    setStateOfMessageForReRenderingToSnackbar = () =>{
        this.setState({
           isSubmittedForm : false
        })
    }


    render() {
        const {isModalOpen,message,messageType,
            isSubmittedForm,isEventStarted, currentNumberOfPeople} = this.state;
        return(
            <Consumer>
                {
                    value =>{
                        const{dispatch} = value;
                        return(
                            <Modal
                                size="lg"
                                show={isModalOpen}
                                onHide={() => this.props.handleClose()}
                                aria-labelledby="example-modal-sizes-title-lg"
                                keyboard = {false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="example-modal-sizes-title-lg">
                                         Update Event
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form onSubmit ={(e) => this.updateEvent(e,dispatch)}>
                                        <Form.Group >
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text"
                                                          placeholder="Name"
                                                          required
                                                          id = "name"
                                                          name="name"
                                                          value = {this.state.name}
                                                          onChange={this.updateInput}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control type="description" placeholder="description"
                                                          required
                                                          id = "description"
                                                          value = {this.state.description}
                                                          name = "latitdescriptionude"
                                                          onChange={this.updateInput}/>
                                        </Form.Group>

                                        <Form.Group >
                                            <Form.Label>Service Date</Form.Label>
                                            <Form.Control type="date" placeholder="Service Date"
                                                          required
                                                          id = "serviceDate"
                                                          value = {this.state.serviceDate}
                                                          name = "serviceDate"
                                                          onChange={this.updateInput}/>
                                        </Form.Group>
                                      
                                        <Form.Group>
                                            <Form.Label>Organization</Form.Label>
                                            <Form.Control type="text" placeholder="Organization"
                                                          required
                                                          id = "org"
                                                        // min ={currentNumberOfPeople}
                                                          value = {this.state.org}
                                                          name = "org"
                                                          onChange={this.updateInput}/>
                                        </Form.Group>

                     

                                        <Form.Group>
                                            <Form.Label>serviceDuty</Form.Label>
                                            <Form.Control type="text" placeholder="serviceDuty"
                                                          required
                                                          id = "serviceDuty"
                                                          value = {this.state.serviceDuty}
                                                          name = "serviceDuty"
                                                          onChange={this.updateInput}/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>signingAuth</Form.Label>
                                            <Form.Control type="text" placeholder="signingAuth"
                                                          required
                                                          id = "signingAuth"
                                                          value = {this.state.signingAuth}
                                                          name = "signingAuth"
                                                          onChange={this.updateInput}/>
                                        </Form.Group>

                                        <Form.Group >
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control type="address" placeholder="address"
                                                          required
                                                          id = "address"
                                                          value = {this.state.address}
                                                          name = "address"
                                                          onChange={this.updateInput}/>
                                        </Form.Group>

                                        <Form.Group >
                                            <Form.Label>City</Form.Label>
                                            <Form.Control type="city" placeholder="City"
                                                          required
                                                          id = "city"
                                                          value = {this.state.city}
                                                          name = "city"
                                                          onChange={this.updateInput}/>
                                        </Form.Group>

                                        <Form.Group >
                                            <Form.Label>State</Form.Label>
                                            <Form.Control type="state" placeholder="State"
                                                          required
                                                          id = "state"
                                                          value = {this.state.state}
                                                          name = "state"
                                                          onChange={this.updateInput}/>
                                        </Form.Group> 

                                        <Form.Group >
                                            <Form.Label>Zip</Form.Label>
                                            <Form.Control type="zip" placeholder="Zip"
                                                          required
                                                          id = "zip"
                                                          value = {this.state.address}
                                                          name = "zip"
                                                          onChange={this.updateInput}/>
                                        </Form.Group>

                                        <Form.Group >
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control type="time" placeholder="Phone"
                                                          required
                                                          id = "phone"
                                                          value = {this.state.phone}
                                                          name = "phone"
                                                          onChange={this.updateInput}/>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="text" placeholder="Email"
                                                          required
                                                          id = "email"
                                                          value = {this.state.email}
                                                          name = "email"
                                                          onChange={this.updateInput}/>
                                        </Form.Group>


                    

                                        <Form.Group>
                                            <Form.Label>hours</Form.Label>
                                            <Form.Control type="hours" placeholder="hours"
                                                          required
                                                          id = "hours"
                                                          value = {this.state.hours}
                                                          name = "hours"
                                                          onChange={this.updateInput}/>
                                        </Form.Group>
                           
                                        <Form.Group>
                                            <Form.Label>Event Image</Form.Label>
                                            <Form.Control type="imageUrl" placeholder="imageUrl"
                                                          required
                                                          id = "imageUrl"
                                                          value = {this.state.imageUrl}
                                                          name = "imageUrl"
                                                          onChange={this.updateInput}/>
                                        </Form.Group>

                                        <Button variant="primary" type="submit"
                                                disabled = {this.state.isDisable}
                                        onClick = {this.setStateOfMessageForReRenderingToSnackbar}>
                                            Submit
                                        </Button>
                                    </Form>
                                </Modal.Body>
                                {isSubmittedForm  || isEventStarted ?
                                    <PositionedSnackbar
                                        message = {message}
                                        messageType = {messageType}
                                        vertical = {"bottom"}
                                        horizontal = {"center"}/> : null}
                            </Modal>

                        );
                    }
                }
            </Consumer>
            )

    }
}

export default withRouter(UpdateEventForm);