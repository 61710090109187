import React, {Component, useState} from 'react';
import {Navbar, Nav,Button,Form,FormControl} from 'react-bootstrap'
import {Link,NavLink} from 'react-router-dom';
import Consumer from '../components/ContextAPI/Context';
import {isOrganizator, isLogin, isParticipant} from "../Authentication";
import Container from 'react-bootstrap/Container';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import NavDropdown from 'react-bootstrap/NavDropdown';

import {
    MDBNavbar,
    MDBContainer,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBCollapse
  } from 'mdb-react-ui-kit';
class NavigationBar extends Component {


    render() {
        const navbar = {
            backgroundColor : "#2ca8ff",
            fontSize : "17px",
            letterSpacing : "1px",
            fontWeight : "500",
            
        }
        const font = {
            fontSize: "15px"
        }


        return (
            <Consumer>
                {
                    value => {
                        const {dispatch,username,authorities} = value;
                        return(
                           

                            <>
                            <Navbar  sticky="top" style = {navbar} collapseOnSelect expand="lg" variant="dark">
                                    
                                        <Navbar.Brand href="/">Chinese Cultural School of Atlanta [VHS] Volunteer Hours System</Navbar.Brand>
                                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                        <Navbar.Collapse id="responsive-navbar-nav">
                                        <Nav className="me-auto nav-item text-white ml-5 mt-1">
                                           
                                           
                                        <a className="nav-link" href="https://atlantaccs.org"  rel="noopener noreferrer">Home </a>

                                           
                                            {  isParticipant() ? <Link className ="nav-link"
                                                to = {"/eventsStudent"}>My Volunteer Hours</Link> : null}

                                            {  isOrganizator() ? <Link className ="nav-link"
                                                to = {"/eventsAdmin"}>Volunteer Report</Link> : null}
                                            
                                            {/* {  <Link className ="nav-link"
                                            to = {"/eligibility"}>Eligibility Requirements</Link>}

                                            {  <Link className ="nav-link"
                                            to = {"/form"}>Form</Link>}    */}

                                                   
                                            <NavDropdown title="Eligibility" id="collasible-nav-dropdown" renderMenuOnMount={true}>
                                                <NavDropdown.Item href="/eligibility">Eligibility Requirements</NavDropdown.Item>
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item href="/form">
                                                    Form
                                                </NavDropdown.Item>
                                            </NavDropdown>
                                          

                                            {/* {  isOrganizator() ? <Link className ="nav-link"
                                                to = {"/raffle"}>Raffle Drawing</Link> : null}
                                            {  isOrganizator() ? <Link className ="nav-link"
                                                to = {"/raffleQ"}>Q Raffle Drawing</Link> : null} */}
                                            {/* {  isOrganizator() ? <Link className ="nav-link"
                                                to = {"/chart"}>Charts</Link> : null} */}
                                            {/* { isOrganizator() ? <Link className ="nav-link"
                                               to = {"/report"}>Dashboard</Link> : null} */}
                                            {/* { isOrganizator() ? <Link className ="nav-link"
                                               to = {"/allPrize"}>Manage Prize</Link> : null}
                                             */}
                                            {/* { isOrganizator() ? <Link className ="nav-link"
                                                to = {"/survey"}>Surveys</Link> : null} */}
                                            {/* {  isParticipant() ? <Link className ="nav-link"
                                                to = {"/events"}>Events</Link> : null} */}
                                            {/* <Link className = { isParticipant() ? "nav-link": "d-none"}
                                                to ={`/myEvents/${localStorage.getItem('username')}`}>
                                                My Events</Link> */}
                                            {/* {  isParticipant() ? <Link className ="nav-link"
                                                to = {"/prize"}>Prizes</Link> : null} */}
                                            {/* <Link className = { isParticipant() ? "nav-link": "d-none"}
                                                to ={`/myReport/${localStorage.getItem('username')}`}>
                                                Dashboard</Link> */}

                                            
                                        </Nav>
                                        <Nav className="ml-auto nav-item text-white ml-5 mt-1">


                                         
                                            
                                            <Navbar.Text className={ isLogin() ? "nav-link text-white ": "d-none"}>
                                                    Hello <a href="#">{localStorage.getItem("loginName")} </a>
                                            </Navbar.Text>
                                  

                                            { !isLogin() ? <Link className ="nav-link" to = {"/login"}>Sign In</Link> : null}
                                            {isOrganizator() ? <Link className ="nav-link" to = {"/register"}>Create new user</Link> : null}
                                            {/* <Link className ="nav-link" to = {"/register"}>Sign New User</Link> */}
                                            <Link className = { isLogin() ? "nav-link": "d-none"}
                                                            to ={"/login"}>
                                            Sign out</Link>
                                      
                                            
                                        </Nav>
                                        </Navbar.Collapse>
                                  
                            </Navbar>









  
                            </>

                      
                        );
                    }
                }
            </Consumer>
        );
    }
}

export default NavigationBar;